/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/common";
import * as i5 from "../header.service";
import * as i6 from "@angular/router";
import * as i7 from "../footer/footer.component.ngfactory";
import * as i8 from "../footer/footer.component";
import * as i9 from "./site-layout.component";
var styles_SiteLayoutComponent = [i0.styles];
var RenderType_SiteLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteLayoutComponent, data: {} });
export { RenderType_SiteLayoutComponent as RenderType_SiteLayoutComponent };
export function View_SiteLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.DOCUMENT, i5.HeaderService, i6.Router, i6.ActivatedRoute], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(5, 114688, null, 0, i8.FooterComponent, [i5.HeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_SiteLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-layout", [], null, null, null, View_SiteLayoutComponent_0, RenderType_SiteLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i9.SiteLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteLayoutComponentNgFactory = i1.ɵccf("app-site-layout", i9.SiteLayoutComponent, View_SiteLayoutComponent_Host_0, {}, {}, []);
export { SiteLayoutComponentNgFactory as SiteLayoutComponentNgFactory };
