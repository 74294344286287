import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';

import { HomeComponent } from './pages/home/home.component';
import { SymptomsChatComponent } from './pages/symptoms-chat/symptoms-chat.component';
import { VirtualPrimaryCareComponent } from './pages/virtual-primary-care/virtual-primary-care.component';
import { ChronicConditionMonitoringComponent } from './pages/chronic-condition-monitoring/chronic-condition-monitoring.component';
import { DoctorQualityRatingsComponent } from './pages/doctor-quality-ratings/doctor-quality-ratings.component';
import { PrescriptionMembershipComponent } from './pages/prescription-membership/prescription-membership.component';
import { LabsMarketComponent } from './pages/labs-market/labs-market.component';
import { ElectronicMedicalRecordsComponent } from './pages/electronic-medical-records/electronic-medical-records.component';

import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';



const routes: Routes = [
    { path: '',
     component: SiteLayoutComponent,
     children:[
    { path: '',
     component: HomeComponent,
     data: {
					navclass: ''
				}
    }, { path: 'terms',
     component: TermsComponent,
     data: {
					navclass: ''
				}
    }, { path: 'privacy',
     component: PrivacyComponent,
     data: {
					navclass: ''
				}
    },{ path: 'symptoms-chat',
     component: SymptomsChatComponent,
     data: {
					navclass: 'nav-green'
				}
    },{ path: 'virtual-primary-care',
     component: VirtualPrimaryCareComponent,
     data: {
					navclass: 'nav-sblue'
				}
    },{ path: 'chronic-condition-monitoring',
     component: ChronicConditionMonitoringComponent,
     data: {
					navclass: 'nav-nblue'
				}
    },{ path: 'doctor-quality-ratings',
     component: DoctorQualityRatingsComponent, 
     data: {
					navclass: 'nav-purple'
				}
    },{ path: 'prescription-membership',
     component: PrescriptionMembershipComponent
    },{ path: 'labs-market',
     component: LabsMarketComponent, 
     data: {
					navclass: 'nav-lpurple'
				}
    },{ path: 'electronic-medical-records',
     component: ElectronicMedicalRecordsComponent, 
     data: {
					navclass: 'nav-red'
				}
    }
]}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
