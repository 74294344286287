import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
export class HamBurger {
isabout: boolean;
constructor(init?: Partial<HamBurger>) {
        Object.assign(this, init);
    }
    
}
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
private subject = new Subject<HamBurger>();
  constructor() { }
     onHam():Observable<HamBurger>{
       return this.subject;
  }
    showabout(){
         this.subject.next(new HamBurger({isabout:true}));
    }
}
