<nav class="navbar fixed-top navbar-light bg-white {{CurrentNavbar}}" [ngClass]="isShadow ? 'shadow-sm' : ''">
  <div class="container ">
    <a class="navbar-brand" [routerLink]="'/'" ><span class=" h3 mb-0 ff-raleway fw-bold"><span class="forn-weight-bold">ALICORN</span><sup class="fw-600  lh-1"  >&reg;</sup></span></a>
      
  </div>
</nav>
<button id="toggle" (click)="toggleham()" [ngClass]="isOpen ? 'open' : ''" class="navbar-toggler {{CurrentNavbar}}"><span></span></button>
     
      <div id="menu" [ngClass]="isOpen ? 'opened' : ''" class="d-flex flex-fill">
          <div class="d-flex flex-column flex-fill " id="burgercontent">
              <div class="menu-body  flex-column flex-fill collapse" id="menumain" [ngClass]="IsAboutclick ? '' : 'show d-flex'">
                  <div class="container d-flex flex-column flex-fill" >
                      <div class="row h-100 justify-content-center  align-items-center  " >
                      <div class="col-lg-6 col-md-7">
                          
                           <ul class="nav flex-column text-start fs-1 fw-bold lh-1 ff-raleway">
  <li class="nav-item">
    <a class="nav-link text-white"  [routerLink]="'/'">Health Solutions</a>

       <ul class="nav flex-column text-start fs-3 ps-3 fw-light lh-1">
  <li class="nav-item">
    <a class="nav-link text-white"  [routerLink]="'/symptoms-chat'">symptoms chat</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/virtual-primary-care'">virtual primary care</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/chronic-condition-monitoring'">chronic condition monitoring</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/labs-market'" >labs market</a>
  </li>
           <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/prescription-membership'" >prescription membership</a>
  </li>
           <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/doctor-quality-ratings'">doctor quality ratings</a>
  </li>
             <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="'/electronic-medical-records'" >electronic medical records</a>
  </li>
</ul>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white"  href="http://ai.alicorn.co" target="_blank">Productivity Solutions</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" [routerLink]="" (click)="toggleabout()">About Us</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" href="https://alicorn.co/products/login" target="_blank" >Login</a>
  </li>
</ul>
                          
                          </div>
                      
                      </div>
                   
         
                      </div>
                  </div>
              
                    <div class="menu-body  flex-column flex-fill collapse" id="menuabout" [ngClass]="IsAboutclick ? 'show d-flex' : ''" 
                         >
                          <div class="container d-flex flex-column flex-fill overflow-hidden" >
                             <div class=" row   " >
                                 <div class="col-md-8 py-2">
                                  
                              <h3 class="text-white mb-0 display-5 fw-bold text-start">About Us</h3>
                                 </div>
                              </div>
                 <div class=" row justify-content-center  overflow-auto" >
                          <div class="col-md-8 overflow-hidden text-start d-flex flex-column  flex-fill ">
                            
                              <div class="fs-4 text-white">
                      <p>We help people live healthier and more productive lives.</p>

<p>We achieve this goal by offering businesses a suite of health and productivity solutions for their employees. </p>

<p>Our health solutions reduce employers’ health insurance costs by 10-25% annually and help employees get easy access to medical care. Our products include SymptomsChat, Virtual Primary Care, Chronic Condition Monitoring, Doctor Quality Ratings, Labs Market, Prescription Membership, and Electronic Medical Records.</p>

<p>Our productivity solutions include chatbots for employees in Sales, Account Management, Benefits, HR, and IT. Our chatbots meet employees where and how they work - by integrating with web chat, Teams, &amp; Slack - and turbocharge revenue generation activities while increasing employee productivity.</p>

<p>We are headquartered in Falls Church, Virginia.</p>
                                  </div>
                                
                              </div>
                      </div>
                        </div>
              </div>
        
              <div class="menu-footer " >
              <div class="container">
                  <div class="row">
    <div class="col-md-6 text-md-start text-center text-white order-1 order-md-0 mb-3">&copy; 2021 ALICORN INC.</div>
    <div class="col-md-6 text-center text-md-end text-center order-0 order-md-1 mb-3">
        <!--<a href="#" class="text-white">Terms</a>&nbsp;&nbsp;
        <a href="#" class="text-white">Privacy</a>-->
        </div>
    </div>
                  </div>
              </div>
              </div>
</div>