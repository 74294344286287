import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-labs-market',
  templateUrl: './labs-market.component.html',
  styleUrls: ['./labs-market.component.scss']
})
export class LabsMarketComponent implements OnInit {
series={
    val1:[149,80,121,11,12,18,65,26,31,79,57,15,20,17,26,21,14,15,22],
    val2:[725,564,462,440,413,410,407,399,332,270,257,237,193,177,153,93,50,44,37],
    name:['Miami','San Francisco','Houston','Tampa, Florida','Los Angeles','Pittsburgh','Dallas','Philadelphia','Boston','Atlanta','Chicago','St. Louis','New York','Milwaukee','Washington','Seattle','Bridgeport, Conn.','Portland, Ore.','Baltimore']
};
    newlist =[];
    
  constructor() { }

  ngOnInit() {
    this.divposition();  
  }
    
    divposition(){
        this.newlist=[];
      for(var i in this.series['val1']){
       var x1:number=(this.series['val1'][i]/1000) * 100;
       var x2:number=((this.series['val2'][i] - this.series['val1'][i])/1000) * 100;
          this.newlist.push({x1:x1.toFixed(2),x2:x2.toFixed(2),min:this.series['val1'][i],max:this.series['val2'][i],name:this.series['name'][i]});
      }
      return this.newlist;
    }

}
