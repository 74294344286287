<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-8  text-center">
        <h1 class="display-1 fw-bold text-ali-nnblue mb-md-5 ff-raleway">Chronic Condition Monitoring</h1>
        <p class="fs--24 text-muted fw-light">A dedicated doctor monitors your chronic conditions<br class="d-none d-lg-block"> through connected medical devices. </p>
        </div>
    </div>
    </div>
    </section>

<section >
<div class="container">
    <div class="row justify-content-center g-0">
    <div class="col-6 text-center">
        <img src="./assets/img/chronic-condition-monitoring/glucometer.png" class="img-fluid" />
        </div>
         <div class="col-6 text-center">
        <img src="./assets/img/chronic-condition-monitoring/blood-pressure monitor.png" class="img-fluid" />
        </div>
    </div>
     <div class="row justify-content-center g-0">
    <div class="col-6 text-center">
        <img src="./assets/img/chronic-condition-monitoring/weight-scale.png" class="img-fluid" />
        </div>
         <div class="col-6 text-center">
        <img src="./assets/img/chronic-condition-monitoring/pulse-oximeter.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-nnblue fs--40 mb-4 fw-bold">Free Medical <br class="d-none d-lg-block">Device Kit for <br class="d-none d-lg-block">Employees.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                  
                <p>You get a free medical device kit that’s pre-connected to your account.</p>
                <p>Every time you take a reading, the data goes directly to a dedicated doctor.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/chronic-condition-monitoring/video-chat.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-nnblue fs--40 mb-4 fw-bold">Monitoring by a <br class="d-none d-lg-block">Dedicated Doctor.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted">
                  
                <p>You get a free medical device kit that’s pre-connected to your account.</p>
                <p>Every time you take a reading, the data goes directly to a dedicated doctor.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/chronic-condition-monitoring/bood-sugar-level-chart.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-nnblue fs--40 mb-4 fw-bold">Doctor Gets <br class="d-none d-lg-block">Your Data <br class="d-none d-lg-block">Automatically.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted">
                   
                <p>Your device data goes directly to your dedicated doctor - every time you take a reading.</p>
                <p>Your doctor knows exactly what’s going on with your diabetes, hypertension, etc.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section>
<div class="container-fluid position-relative">
    <div class="row">
    <div class="col-md-12">
        
        <img src="./assets/img/chronic-condition-monitoring/chat-1.png" class="img-fluid" />
        </div>
    </div>
    <div class="px-3 position-absolute top-0 start-0 w-100 py-md-5 mt-md-5 py-3 mt-3 ">
    <div class="row  justify-content-end pe-lg-5">
    
    <div class="col-lg-5 col-7 text-white">
        <div class="d-flex no-gutters mb-md-4 mb-2">
        <div  > 
            <div class="rounded-pill fs-5 bg-ali-nnblue text-white p-lg-3 px-lg-5 p-2 px-3 chat-box">  Hi, this is your doctor</div> </div>
        </div>
        
        <div class="d-flex justify-content-end mb-lg-4 mb-2">
        <div >
            <div class="rounded-pill fs-5 bg-ali text-white p-lg-3 px-lg-5 p-2 px-3 chat-box"> Hello, doctor</div> </div>
        </div>
        
        <div class="d-flex mb-md-4 mb-2">
        <div> 
            <div class="rounded-pill fs-5 bg-ali-nnblue text-white p-lg-3 px-lg-5 p-2 px-3 chat-box text-nowrap"> Your blood pressure is high…</div></div>
        </div>
        
        <div class="d-flex justify-content-end">
        <div>
            <div class="rounded-pill fs-5 bg-ali text-white p-lg-3 px-lg-5 p-2 px-3 chat-box"> I may need medication…</div></div>
        </div>
        
        </div>
    </div>
        </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-nnblue fs--40 mb-4 fw-bold">Proactive <br class="d-none d-lg-block">Doctor <br class="d-none d-md-block">Intervention.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted">
                    
                <p>Your dedicated doctor reaches out via text or video visit if your readings are off.</p>
                <p>Your health issues are fixed in no time.</p>
           </div>
       </div>
    </div>
</section>
<section>
<div class="container-fluid position-relative">
    <div class="row">
    <div class="col-md-12">
        
        <img src="./assets/img/chronic-condition-monitoring/chat-2.png" class="img-fluid" />
        </div>
    </div>
    <div class="px-3 position-absolute top-0 start-0 w-100 py-md-5 mt-md-5 py-0 mt-0 h-100 ">
    <div class="row  justify-content-end pe-lg-4 h-100">
    
    <div class="col-lg-5 col-7 text-white d-flex  align-items-md-start align-items-end">
        <div class="flex-fill">
        <div class="d-flex justify-content-center no-gutters mb-md-4 mb-2">
        <div  > 
            <div class="rounded-pill  bg-ali-nnblue text-white text-center p-lg-3 px-lg-4 p-2 px-3 chat-box">  <div class="fs-16 fw-600">Reminder: Morning Medications</div>
<div class="fs-13">Have you done this today?</div></div> </div>
        </div>
        
        <div class="row g-0 justify-content-center mb-lg-2 mb-2">
        <div class="col-md-4">
            <div class="rounded-pill text-center fs--14 bg-ali-green text-white p-lg-2 px-lg-4 p-2 px-3 chat-box"> Yes</div> </div>
        </div>
        <div class="row g-0 justify-content-center mb-lg-2 mb-2">
        <div class="col-md-4">
            <div class="rounded-pill text-center fs--14 bg-ali-red text-white p-lg-2 px-lg-4 p-2 px-3 chat-box"> No</div> </div>
        </div> 
        </div>
        </div>
    </div>
        </div>
    </div>
</section>

<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-nnblue fs--40 mb-4 fw-bold">Medication <br class="d-none d-lg-block">Adherence.  </h4>
           </div> 
            <div class="col-lg-5 col-md-7 fs--22 text-muted"> 
                <p>Your dedicated doctor sets up medication reminders.</p>
                <p>A little nudge can go a long way in taking care of your health!</p>
           </div>
       </div>
    </div>
</section>


