<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-7 text-center">
        <h1 class="display-1 fw-bold text-ali-green mb-md-5 ff-raleway">SymptomsChat<sup>TM</sup></h1>
        <p class="fs--24 text-muted fw-light">Easy and accurate symptoms assessment technology that helps you understand what type of care is needed.</p>
        </div>
    </div>
    </div>
    </section>
<section>
<div class="container-fluid position-relative">
    <div class="row">
    <div class="col-md-12">
        
        <img src="./assets/img/feature.png" class="img-fluid" />
        </div>
    </div>
    <div class="px-3 position-absolute top-0 start-0 w-100 py-md-5 mt-md-5 py-3 mt-3 ">
    <div class="row  ps-lg-5">
    
    <div class="col-lg-5 col-7 text-white">
        <div class="d-flex no-gutters mb-md-4 mb-2">
        <div  > 
            <div class="rounded-pill fs-5 bg-ali-green text-white p-lg-3 px-lg-5 p-2 px-3 chat-box">  Hi, how can I help?</div> </div>
        </div>
        
        <div class="d-flex justify-content-end mb-lg-4 mb-2">
        <div >
            <div class="rounded-pill fs-5 bg-ali text-white p-lg-3 px-lg-5 p-2 px-3 chat-box"> I have a headache</div> </div>
        </div>
        
        <div class="d-flex mb-md-4 mb-2">
        <div> 
            <div class="rounded-pill fs-5 bg-ali-green text-white p-lg-3 px-lg-5 p-2 px-3 chat-box text-nowrap"> Please specify your gender</div></div>
        </div>
        
        <div class="d-flex justify-content-end">
        <div>
            <div class="rounded-pill fs-5 bg-ali text-white p-lg-3 px-lg-5 p-2 px-3 chat-box"> Female</div></div>
        </div>
        
       
      
       
       
        
        
        </div>
    </div>
        </div>
    </div>
</section>

<section class="section-1">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-green fs--40 mb-4 fw-bold">It’s Like a <br class="d-none d-lg-block">Medical Doctor Checkup.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                <p>
                SymptomsChat triage is like a medical doctor checkup.</p>
                <p>Based on symptoms you describe, a series of questions will be asked guided by artificial intelligence. You’ll then receive accurate and reliable triage information to decide your course of action.</p>
                
                <p>  This helps you prevent unnecessary visits, minimize over and under-treatment, and use virtual care when appropriate.</p>
               
           </div>
       </div>
    </div>
</section>

<section class="section-1">
   <div class="container">
       <div class="row justify-content-center">
    
            <div class="col-7 d-flex align-items-center">
        <img src="./assets/img/arrows-green.png" class="img-fluid" />
        
           </div>
            <div class="col-3">
        <img src="./assets/img/icon-group-1.png" class="img-fluid" />
        
           </div>
       </div>
    </div>
</section>

<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-green fs--40 mb-4 fw-bold">Helps You <br class="d-none d-lg-block">Choose an <br class="d-none d-lg-block">Appropriate <br class="d-none d-lg-block">Care Option.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 
                <p>
               Skip Dr. Internet - the information on the web is often not reliable. Using our technology, you can easily and accurately identify options for care: home remedies, virtual care, in-person visit, or emergency treatment.</p>
                <p>Symptoms assessment triage tools have shown that 19% of face-to-face visits can be done virtually saving you time and money.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section class="section-1">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-md-8 position-relative ai-box">
        <img src="./assets/img/ai-group.png" class="img-fluid position-relative" />
        <div class="position-absolute top-50 start-50 translate-middle rounded bg-ali-green p-4 ai-box-green"></div>
           </div>
           
       </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-green fs--40 mb-4 fw-bold">Technology <br class="d-none d-lg-block">You Can Rely <br class="d-none d-lg-block">On. </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 
                <p>
             The accuracy of the symptoms assessment technology is 93% and is confirmed by cases from NEJM, JAMA, BMJ, and others.</p>
                <p> Scientific studies from well-respected publications and statistical data from thousands of patient cases are used to create the medical database.</p>
                
               
           </div>
       </div>
    </div>
</section>