import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class HamBurger {
    constructor(init) {
        Object.assign(this, init);
    }
}
export class HeaderService {
    constructor() {
        this.subject = new Subject();
    }
    onHam() {
        return this.subject;
    }
    showabout() {
        this.subject.next(new HamBurger({ isabout: true }));
    }
}
HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(); }, token: HeaderService, providedIn: "root" });
