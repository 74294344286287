import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SymptomsChatComponent } from './pages/symptoms-chat/symptoms-chat.component';
import { VirtualPrimaryCareComponent } from './pages/virtual-primary-care/virtual-primary-care.component';
import { ChronicConditionMonitoringComponent } from './pages/chronic-condition-monitoring/chronic-condition-monitoring.component';
import { DoctorQualityRatingsComponent } from './pages/doctor-quality-ratings/doctor-quality-ratings.component';
import { PrescriptionMembershipComponent } from './pages/prescription-membership/prescription-membership.component';
import { LabsMarketComponent } from './pages/labs-market/labs-market.component';
import { ElectronicMedicalRecordsComponent } from './pages/electronic-medical-records/electronic-medical-records.component';
import { ProgressbarComponent } from './ui/progressbar/progressbar.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SiteLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SymptomsChatComponent,
    VirtualPrimaryCareComponent,
    ChronicConditionMonitoringComponent,
    DoctorQualityRatingsComponent,
    PrescriptionMembershipComponent,
    LabsMarketComponent,
    ElectronicMedicalRecordsComponent,
    ProgressbarComponent,
    TermsComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
