<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <h1 class="display-1 fw-bold text-ali-purple mb-md-5 ff-raleway">Doctor Quality Ratings</h1>
        <p class="fs--24 text-muted fw-light">The first comprehensive ratings tool for the quality and <br class="d-none d-md-block">costs of primary care providers and specialists.   </p>
        </div>
    </div>
    </div>
    </section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/doctor-quality-ratings/repeat-grid-2.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="py-3 py-md-4 pt-lg-5">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-purple fs--40 mb-4 fw-bold">Doctor Quality Information is <br class="d-none d-lg-block">Not on Google.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                      
                <p>Your employees need confidence in choosing doctors, yet the doctor quality data they need is not available.</p>
                <p>90% of patients rely on online reviews to evaluate physicians using Google and other ratings websites that don’t offer any quality information.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section class="py-3 py-md-4 pt-lg-5">
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/doctor-quality-ratings/health-insurer-website-quality-ratings.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class=" py-3 py-md-4 pt-lg-5">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-purple fs--40 mb-4 fw-bold">Insurance Websites Don’t Offer Quality <br class="d-none d-lg-block">Data Either.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                      
                <p>Health insurance companies don’t offer quality ratings of doctors.</p>
                <p>If they do, the quality information is not meaningful to compare doctors or understand what makes a doctor better. </p>
                
               
           </div>
       </div>
    </div>
</section>
<section class="py-3 py-md-4 pt-lg-5">
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/doctor-quality-ratings/largest-claims-dataset.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class=" py-3 py-md-4 pt-lg-5">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-purple fs--40 mb-4 fw-bold">Our Tool Uses Massive &amp; Rich Dataset to Create Quality Scores. <br class="d-none d-lg-block"> </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                      
                <p>10 billion medical claims of Medicare and Medicaid beneficiaries over 10 years are analyzed to create our doctor quality ratings. </p>
                <p>In total, our combined Medicare and Medicaid dataset rivals or exceeds the coverage of the largest US health insurers.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section class="py-3 py-md-4 pt-lg-5">
<div class="container">
    <div class="row justify-content-center my-3 my-md-0">
    <div class="col-md-12 text-center">
        <img src="./assets/img/doctor-quality-ratings/doctor-quality-ratings-tool-example-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="py-3 py-md-4 pt-lg-5">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-purple fs--40 mb-4 fw-bold">Our Doctor <br class="d-none d-lg-block">Quality Ratings Tool.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                        
                <p>Our Doctor Quality Ratings is a simple online tool with a familiar 5-star rating system that allows employees to easily find the best doctors.</p>
                <p>Employees can see a doctor’s quality performance at the outcomes level to make a decision based on what’s important to them.</p>
                <p>Cost ratings of doctors are included to help apply both quality and cost criteria.</p>
                
               
           </div>
       </div>
    </div>
</section>