import { Routes } from '@angular/router';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { SymptomsChatComponent } from './pages/symptoms-chat/symptoms-chat.component';
import { VirtualPrimaryCareComponent } from './pages/virtual-primary-care/virtual-primary-care.component';
import { ChronicConditionMonitoringComponent } from './pages/chronic-condition-monitoring/chronic-condition-monitoring.component';
import { DoctorQualityRatingsComponent } from './pages/doctor-quality-ratings/doctor-quality-ratings.component';
import { PrescriptionMembershipComponent } from './pages/prescription-membership/prescription-membership.component';
import { LabsMarketComponent } from './pages/labs-market/labs-market.component';
import { ElectronicMedicalRecordsComponent } from './pages/electronic-medical-records/electronic-medical-records.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
const ɵ0 = {
    navclass: ''
}, ɵ1 = {
    navclass: ''
}, ɵ2 = {
    navclass: ''
}, ɵ3 = {
    navclass: 'nav-green'
}, ɵ4 = {
    navclass: 'nav-sblue'
}, ɵ5 = {
    navclass: 'nav-nblue'
}, ɵ6 = {
    navclass: 'nav-purple'
}, ɵ7 = {
    navclass: 'nav-lpurple'
}, ɵ8 = {
    navclass: 'nav-red'
};
const routes = [
    { path: '',
        component: SiteLayoutComponent,
        children: [
            { path: '',
                component: HomeComponent,
                data: ɵ0
            }, { path: 'terms',
                component: TermsComponent,
                data: ɵ1
            }, { path: 'privacy',
                component: PrivacyComponent,
                data: ɵ2
            }, { path: 'symptoms-chat',
                component: SymptomsChatComponent,
                data: ɵ3
            }, { path: 'virtual-primary-care',
                component: VirtualPrimaryCareComponent,
                data: ɵ4
            }, { path: 'chronic-condition-monitoring',
                component: ChronicConditionMonitoringComponent,
                data: ɵ5
            }, { path: 'doctor-quality-ratings',
                component: DoctorQualityRatingsComponent,
                data: ɵ6
            }, { path: 'prescription-membership',
                component: PrescriptionMembershipComponent
            }, { path: 'labs-market',
                component: LabsMarketComponent,
                data: ɵ7
            }, { path: 'electronic-medical-records',
                component: ElectronicMedicalRecordsComponent,
                data: ɵ8
            }
        ] }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
