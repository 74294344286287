import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chronic-condition-monitoring',
  templateUrl: './chronic-condition-monitoring.component.html',
  styleUrls: ['./chronic-condition-monitoring.component.scss']
})
export class ChronicConditionMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
