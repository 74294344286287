<section class="py-5">
<div class="container">

<div class="row">
    
    <div class="col-md-12">
    <h2 class="lh-sm lh-lg-1 mb-md-4 fw-bold hero-2" >The Complete <br class="d-none d-lg-block">VirtualCare System<sup>TM</sup>.</h2> 
        <h3 class="display-2 fw-600"> <small class="text-muted">For Businesses.</small></h3>
        <br>
        <h3 class="display-3 lh-sm lh-lg-1 mb-md-4 text-ali-green fw-600">Save 10-25% on health <br class="d-none d-lg-block">insurance costs.</h3>
        <p class="fs-2"><a href="#" class="fw-600">Starting $10/month</a></p>
    </div>
    </div>
</div>
    </section>

<section class="pb-5">
    <div class="container">

<div class="row justify-content-center">
    
    <div class="col-md-10">
        <img src="./assets/img/landing-page-people.png" class="img-fluid">
    </div>
        </div>
    </div>
    
</section>

<section class="section bg-ali-nblue text-white">
     <div class="container">
         <div class="row gy-3">
    
    <div class="col-lg-6 col-md-8">
        <h4 class="fs--42">Employer savings</h4>
        <br>
        <h2 class="display-3 lh-sm lh-lg-1 mb-3 mb-md-5 fw-bold" style="line-height: 1.1 !important;">We cut your  <br class="d-none d-lg-block">health insurance <br class="d-none d-lg-block">costs with $0 <br class="d-none d-lg-block">medical claims.</h2>
        <div class="fs--26  ">
         
        <p>  Medical claims drive health insurance costs. If you cut claims, you cut costs.</p>
        <p>There are no medical claims, no insurance billing for our virtual visits or chronic condition monitoring and that cuts insurance premiums by 10-25%.</p>
          
        </div>
             </div>
             <div class="col-lg-6 col-md-4 d-flex justify-content-center align-items-center">
                 <div class="row g-0">
                 <div class="col-lg-12">
                     <img src="./assets/img/health-solutions/humaaans-sitting-6.png" class="img-fluid">
                     </div>
                 </div>
                 
             </div>
         </div>
         
           
    </div>
</section>
<section class="section">
     <div class="container">
        <div class="row gy-3">
     <div class="col-lg-6 col-md-4 d-flex justify-content-center align-items-center order-1 order-md-0">
                 <div class="row g-0">
                 <div class="col-lg-12">
                     <img src="./assets/img/health-solutions/group-42.png" class="img-fluid">
                     </div>
                 </div>
                 
             </div>
    <div class="col-lg-6 col-md-8 order-0 order-md-1">
        <h4 class="fs--42 text-muted">Employee savings</h4>
        <br>
        <h2 class="display-3 lh-sm lh-lg-1 mb-3 mb-md-5 fw-bold" style="line-height: 1.1 !important;">We cut your <br class="d-none d-lg-block">employee costs <br class="d-none d-lg-block">with $0 copay &amp; <br class="d-none d-lg-block">$0 deductible.  </h2>
       <div class="fs--26  text-muted">
        
        <p>There are <b class="fw-800">no out-of-pocket costs </b>for any virtual visits or chronic condition coaching or mental health sessions.</p>
        <p>That saves money. Especially for employees in a high deductible health plan (HDHP) — with or without a Health Savings Account (HSA).</p> 
        </div>
             </div>
            
         </div>
         
           
        
    </div>
</section>
<section class="section bg-ali-nblue text-white">
     <div class="container">
         <div class="row gy-3">
    
    <div class="col-lg-6 col-md-8 ">
        <h4 class="fs--42">Great Experience</h4>
        <br>
        <h2 class="display-3 lh-sm lh-lg-1 mb-3 mb-md-5 fw-bold" style="line-height: 1.1 !important;">1-Stop <br class="d-none d-lg-block">solution makes it easy for <br class="d-none d-lg-block">employees. </h2>
        <div class="fs--26  ">
        
        <p> The Complete VirtualCare System supports the entire care journey and delivers exceptional employee satisfaction.</p>
        <p>  Everything your employees need when they are sick is in one place - making it easy for your employees to get healthy and <b class="fw-800">get back to work quickly</b>.</p>
          
        </div>
             </div>
             <div class="col-lg-6 col-md-4 d-flex justify-content-center align-items-center">
                 <div class="row g-0">
                 <div class="col-lg-12">
                     <img src="./assets/img/health-solutions/group-35.png" class="img-fluid">
                     </div>
                 </div>
                 
             </div>
         </div>
         
           
    </div>
</section>

<section class="section">
     <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-5"></div>
            <div class="col-md-6 col-lg-5  text-muted">
                <div class="row g-0">
                 <div class="col-8">
                     <img src="./assets/img/health-solutions/nikolas-gogol.png" class="img-fluid">
                     </div>
                 </div>
                <div class="d-flex flex-column">
            <blockquote class="blockquote fs--26 fw-600 mb-md-0 order-0 order-md-0 ">
                 
  <p> “I would rather lose my <br class="d-none d-lg-block">insurance than this benefit!.” </p>
  
</blockquote>
                    <hr class="border-2 border-drak order-2 order-md-1" style="opacity: 1; height: 2px;"/>
                <p class="fw-800 text-dark fs--20 order-1 order-md-2 mb-0 mb-md-2">TARREN BRAGDON, CEO <br>Health Access Solutions</p>
                    </div>
            </div>
         </div>
         <div class="row justify-content-center  mt-md-n5">
            <div class="col-md-6 col-lg-5 order-0 order-md-1"></div>
            <div class="col-md-6 col-lg-5  text-muted order-1 order-md-0">
                 <div class="row g-0">
                 <div class="col-8">
                     <img src="./assets/img/health-solutions/david-kane.png" class="img-fluid">
                     </div>
                 </div>
                   <div class="d-flex flex-column">
            <blockquote class="blockquote fs--26 fw-600 mb-md-0 order-0 order-md-0 ">
                   
  <p> “I have been a benefits  <br class="d-none d-lg-block">advisor for a long time. <br class="d-none d-lg-block"> My experience has been <br class="d-none d-lg-block">fantastic and I am a <br class="d-none d-lg-block"> big fan.”</p>
   
</blockquote>
                             <hr class="border-2 border-drak order-2 order-md-1" style="opacity: 1; height: 2px;"/>
                  <p class="fw-800 text-dark fs--20 order-1 order-md-2 mb-0 mb-md-2">DAVID KANE, SR. CONSULTANT <br>YORK International</p>
                </div>
            </div>
         </div>
          <div class="row justify-content-center  mt-md-n5">
            <div class="col-md-6 col-lg-5"></div>
            <div class="col-md-6 col-lg-5  text-muted">
                
                <div class="row g-0">
                 <div class="col-8">
                     <img src="./assets/img/health-solutions/vineeta-mooganur.png" class="img-fluid">
                     </div>
                 </div>
                   <div class="d-flex flex-column">
            <blockquote class="blockquote fs--26 fw-600 mb-md-0 order-0 order-md-0 ">
                     
  <p>“I used to pay $1,600 for my <br class="d-none d-lg-block">concierge doctor. With this service <br class="d-none d-lg-block">I save time away from office and<br class="d-none d-lg-block"> don’t pay copays.”</p>
 
  
</blockquote>
                             <hr class="border-2 border-drak order-2 order-md-1" style="opacity: 1; height: 2px;"/>
                     <p class="fw-800 text-dark fs--20 order-1 order-md-2 mb-0 mb-md-2">PAULA THOMPSON, SENIOR EXECUTIVE <br>eGlobal Tech</p>
                </div>
            </div>
         </div>
    </div>
</section>
<section class="section-1 bg-ali-nblue text-white">
     <div class="container">
         <div class="row ">
             <div class="col-md-12 text-center">
              <h2 class="display-4 lh-sm lh-lg-1 mb-0 " style="line-height: 1.2 !important;">The Complete VirtualCare System. <br class="d-none d-lg-block">  Helps you from symptoms back to health.</h2>
             </div>
         </div>
    </div>
</section>

<section class="section">
     <div class="container">
         <div class="row justify-content-center gy-2 ">
            <div class="col-md-6 col-lg-6 order-0 order-md-1 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6   order-1 order-md-0 mb-3">
            <h4 class="fs--38">When you have symptoms</h4> 
        <h2 class="display-4 lh-sm lh-lg-1 mb-md-4 fw-bold text-ali-green" style="line-height: 1.2 !important;">SymptomsChat</h2>
        <div class="fs--24  text-muted">
        
        <p> Easy and accurate symptoms assessment technology that helps you understand care options.</p>
      <p class="fs-4"><a [routerLink]="'/symptoms-chat'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
                
                
            </div>
         </div>
        <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6  mb-3">
                <h4 class="fs--38">When you are sick</h4> 
        <h2 class="display-4 lh-sm lh-lg-1 mb-md-4 fw-bold text-ali-sblue" style="line-height: 1.1 !important;">Virtual Primary Care</h2>
        <div class="fs--24  text-muted">
        
        <p> Unlike Telemedicine, our service offers 1:1 doctor relationship and unlimited virtual visits.</p>
      <p class="fs-4"><a [routerLink]="'/virtual-primary-care'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
         <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 order-0 order-md-1 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6   order-1 order-md-0 mb-3">
              <h4 class="fs--38">If you have diabetes, hypertension etc.</h4> 
        <h2 class="display-4 lh-1 mb-md-4 fw-bold text-ali-nnblue" style="line-height: 1.1 !important;">Chronic Condition Monitoring</h2>
        <div class="fs--24  text-muted">
        
        <p> A dedicated doctor monitors your chronic conditions via connected medical devices.</p>
      <p class="fs-4"><a [routerLink]="'/chronic-condition-monitoring'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
          <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6  mb-3">
            <h4 class="fs--38">When you need medication</h4> 
        <h2 class="display-4 lh-1 mb-md-4 fw-bold text-ali-sblue" style="line-height: 1.2 !important;">Prescription Membership</h2>
        <div class="fs--24  text-muted">
        
        <p>Get up to 90% discounts by using our membership card at major pharmacy stores.</p>
      <p class="fs-4"><a [routerLink]="'/prescription-membership'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
           <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 order-0 order-md-1 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6   order-1 order-md-0 mb-3">
              <h4 class="fs--38">When you need tests</h4> 
        <h2 class="display-4 lh-1 mb-md-4 fw-bold text-ali-lpurple" style="line-height: 1.1 !important;">Labs Market</h2>
        <div class="fs--24  text-muted">
        
        <p> Our doctors find lower cost facilities for your labs/tests and send the order electronically.</p>
      <p class="fs-4"><a [routerLink]="'/labs-market'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
          <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6  mb-3">
            <h4 class="fs--38">When you need a specialist</h4> 
        <h2 class="display-4 lh-1 mb-md-4 fw-bold text-ali-purple" style="line-height: 1.2 !important;">Doctor Quality Ratings</h2>
        <div class="fs--24  text-muted">
        
        <p>The first comprehensive ratings tool that helps you find the best doctors based on their quality and outcomes.</p>
      <p class="fs-4"><a [routerLink]="'/doctor-quality-ratings'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
         
         <div class="row justify-content-center gy-2">
            <div class="col-md-6 col-lg-6 order-0 order-md-1 d-none d-md-block mb-3"></div>
            <div class="col-md-6 col-lg-6   order-1 order-md-0 mb-3">
              <h4 class="fs--38">Before you see a specialist</h4> 
        <h2 class="display-4 lh-1 mb-md-4 fw-bold text-ali-red" style="line-height: 1.1 !important;">Electronic Medical Records</h2>
        <div class="fs--24  text-muted">
        
        <p> 1-click access to all your medical records. We collect them for you and you focus on getting better.</p>
      <p class="fs-4"><a [routerLink]="'/electronic-medical-records'" class="fw-600">Learn more &gt;</a></p>
          
        </div>
            </div>
         </div>
    </div>
</section>