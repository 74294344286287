<section class="section-1 pb-0">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
        <h1 class="display-1 fw-bold text-ali-red mb-md-5 ff-raleway">Electronic Medical Records</h1>
        <p class="fs--24 text-muted fw-light">1-click access to all your medical records. <br class="d-none d-md-block">We collect and organize your electronic medical records. </p>
        </div>
    </div>
    </div>
    </section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/electronic-medical-records/processing-times-for-medical-records.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="py-5 pt-md-6">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-red fs--40 mb-4 fw-bold">Collecting <br class="d-none d-lg-block">Medical <br class="d-none d-lg-block">Records is <br class="d-none d-lg-block">Time <br class="d-none d-lg-block">Consuming. </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                   
                <p>The Yale study published in JAMA found varying release times for medical records from hospitals.</p>
                <p>In 18% of the cases, it took more than a month to get medical records.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/electronic-medical-records/format-for-releasing-medical-records.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="py-5 pt-md-6">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-red fs--40 mb-4 fw-bold">It’s Not Easy to <br class="d-none d-lg-block">Get Medical <br class="d-none d-lg-block">Records &amp; It <br class="d-none d-lg-block">Might Cost You <br class="d-none d-lg-block">Money. </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                    
                <p>The Yale study published in JAMA also found varying formats for medical records from hospitals.</p>
                <p>The cost charged by hospitals for releasing medical records for a 200-page document ranged from $0 to $281.54.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/electronic-medical-records/group-87.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="py-5 pt-md-6">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-red fs--40 mb-4 fw-bold">We Give You 1-Click Access to <br class="d-none d-lg-block">Your Medical <br class="d-none d-lg-block">Records.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                     
                <p>We collect and organize your medical records. You focus on getting better.</p>
                <p>You can share your medical records in a single downloadable document with any doctor or caregiver.</p>
                
               
           </div>
       </div>
    </div>
</section>