<section class="section-1 pb-0">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-md-12 ">
        <h1 class="display-1 fw-bold   mb-md-5 ff-raleway">Privacy Policy</h1>
         
        </div>
    </div>
    </div>
    </section>
<section class="section pt-0">

<div class="container">
    <div class="row justify-content-center">
    <div class="col-md-12 fs--22">
        
        <p class="mb-4">Last updated: January 4, 2021</p>
        <p></p>
        <p class="mb-4"> This Privacy Policy notice (“Notice”) describes how medical information about you may be used and disclosed and how you can get access to this information. Please review it carefully.</p>
        
        <p class="mb-4">This Notice, as required by law, details information about your rights and our legal responsibilities as they pertain to confidential patient information. Although we maintain the right to change and update the terms of this notice as needed, we are required to comply with the provisions of this notice as presented. Interested parties can obtain a written version of this Notice by emailing support@alicorn.co or view it on our official websites, https://alicorn.co </p>
        
        
        <p class="mb-4">General: Patients using ALICORN’s services and platforms for online medical visits must be eighteen years old or be a legal representative of an underage patient, and have the authority to agree to ALICORN Terms of use and agree to register for ALICORN services in their own legal name. </p>
        
        
        <p class="mb-4">Your Clinician: You understand that the provider of medical services will be physician entities contracted by ALICORN, and that you are entering into a confidential physician-patient relationship with a licensed provider employed or contracted by such physician entities. </p>
        
        
        
        <p class="mb-4">The licensed clinicians providing care for you through physician entities contracted by ALICORN may be incorporated in your state, and understand that your medical information is confidential and make every effort to keep your private information as secure and protected as possible. As required by law, all information pertaining to your health, provision of care, referrals, or reimbursement, whether obtained or disclosed, will be kept private and not shared with any unauthorized party. Your confidential information may, on occasion and when required, be shared with the appropriate health care authorities as needed to provide treatment.</p>
        
        
        <p class="mb-4"> Administration: You understand that ALICORN, Inc., provides management and administration services to the contracted physician entities, and does not engage in any provision of medical services. </p>
        
        
        <p class="mb-4"> Information Confirmation: You agree for being responsible in confirming any information pertaining to yourself or the minor you may be legally representing, including information by any third party.</p>
        
        
        
        <p class="mb-4"> Electronic Medical Records: Medical record keeping at physician entities contracted by ALICORN is electronic and kept secure per required HIPAA standards. You agree to the storing of your medical information electronically, and understand that though every safeguarding effort is made to keep your records secure, no electronic record system is 100% secure. In accordance with our Privacy Notice, your medical records will not be shared with anyone without your written consent or as required by law. </p>
        
        
        
        <p class="mb-4"> Communication: You understand that your virtual visits will be conducted by secure online video conferencing, text messaging, or phone with clinicians contracted by ALICORN based in your state, and/or other states. </p>
        
        
        
        <p class="mb-4">Prescription Limitations: You agree that clinicians contracted by ALICORN cannot prescribe medicines that are narcotics or controlled by the DEA (Drug Enforcement Administration), prescriptions that are restricted by state law, or certain prescriptions for psychiatric conditions, including but not limited to ADD, schizophrenia, insomnia, or chronic anxiety. </p>
        
        
        <p class="mb-4">Risk: You understand that despite all efforts put forth by ALICORN and it’s contracted physician entities, there exist risks that include, but are not limited to, 1) power and connectivity failure that impacts electronic communication; 2) loss of medical records due to unanticipated equipment failure; 3) unauthorized and illegal hacking of electronic medical records; and 4) unexpected electronics or feature malfunction. </p>
        
        
        <p class="mb-4">Rights: You understand that when using ALICORN services you have the following rights:</p> 
        
        
        <p class="mb-4">Choice - You have the choice, and right, to refuse consent with the clinician virtual visits anytime, without impact to your right to future care. Access to Information - You have the right to request a copy of all your medical records. Privacy - You have the right to have your private medical information protected as required by law, and that this information shall not be shared with anyone without your consent or as required by law. </p>
        
        
        <p class="mb-4"> Consent to Share Medical Information - You understand that your consent to participate in medical visits extends to sharing your private medical information with other healthcare officials that may need to be involved in your care. You have the right to know who your medical information is being shared with under these circumstances. </p>
        
        
        <p class="mb-4"> Uses &amp; Disclosures of Medical Information: ALICORN and/or its contracted clinicians may use or disclose your medical information as needed in the pursuit of your treatment, reimbursement, and other matters directly related to your consultation. Some of the circumstances whereby we may use or disclose your information are listed below, though this is not a comprehensive list of circumstances:</p> 
        
        
        
        <p class="mb-4">Treatment - your clinician may need, or need to share information about your condition or health with appropriate specialists to effectively manage your health. </p>
        
        <p class="mb-4">Reimbursement - information may have to be shared with your benefits payer to pursue or record medical claims, or as needed with collection authorities to collect reimbursement for services rendered. </p>
        
        
        <p class="mb-4"> Clinic Administration - information may have to be collected and aggregated internally within ALICORN and/or its contracted physician entities in pursuit of quality control, scheduling of appointments, determining triage, processing referrals for medical treatment, sorting of medical records, and other such tasks necessary to operate a medical establishment. If used for research or studies, ALICORN and/or its contracted physician entities shall remove identifiable information to maintain confidentiality. </p>
        
        
        
        
        <p class="mb-4"> Requests for Information by Authorities - information may have to be shared with certain institutions to the extent required by law for circumstances, including, but not limited to, worker compensation, military matters, legal requests or summons, law enforcement or criminal matters, insurance policies, and authorized government institutions such as local health departments, the Center for Disease Control (CDC), and the Drug Enforcement Agency (DEA). </p>
        
        
        
        <p class="mb-4">Family, Next of Kin, and Authorized Recipients - as per your consent, your records may be shared with whomever you authorize by name, and you have the right to revoke any previous authorization at any time.</p>
        
        
        <p class="mb-4">Circumstances with Higher Protection - certain circumstances including, but not limited to, HIV status, mental health conditions, and genetic conditions require a higher level of protection against sharing of your medical information, and appropriate federal or state laws will be applied in these circumstances. </p>
        
        
        
        <p class="mb-4"> Incidental Uses or Disclosures - you understand that routine, incidental uses or disclosures will occur in permitted situations, and that such incidental use or disclosure is not a violation of your privacy. </p>
        
        
        
        <p class="mb-4">You Have Rights: Please note the following list of your rights. Please contact support@alicorn.co to request any of the following: </p>
        
        
        
        <p class="mb-4"> You have the right to limit uses and disclosures of your private information, although your request may be denied unless said disclosure is to health authorities, information which does not have outstanding financial obligation, and the disclosure is not required by law. You have the right to reasonably determine how and where your sensitive medical information is delivered. </p>
        
        
        
        <p class="mb-4">  You have the right to access your medical information, except for in the following cases: Behavioral health notes that are separate from your medical record; Information being used, or being assembled in anticipation of legal cases; Research cases where you have consented to refraining from access; Where your access may be restricted by federal law; Access to your medical information by sources not under our control; Circumstances where there may be potential for harm to yourself or others. In these circumstances, an appeal process shall be available to you. </p>
        
        
        
        <p class="mb-4"> You have the right to request changes to your medical information, though ALICORN and/or its contracted physician entities may not implement such changes in special situations. Changes implemented shall be added as amendments, and shall not replace existing medical information. </p>
        
        
        <p class="mb-4">You have the right to request a list of disclosures of your medical information made to others, except in cases where disclosures were made for treatment, incidental, authorized by you, for law enforcement, or legal reasons. </p>
        
        
        <p class="mb-4">  In the event of a security breach that may compromise your medical information, you have the right to receive notification. Questions &amp; Complaints: If you have questions regarding this Notice, please contact us at support@alicorn.co </p>
        
        <p class="mb-4"> If you suspect your privacy rights have been violated, please contact ALICORN at support@alicorn.co</p>
        
        </div>
    </div>
    </div>
</section>