<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-9 text-center">
        <h1 class="display-1 fw-bold text-ali-sblue mb-md-5 ff-raleway">Virtual Primary Care</h1>
        <p class="fs--24 text-muted fw-light">Unlike Telemedicine, our service offers 1:1 doctor <br class="d-none d-lg-block"> relationship and unlimited virtual visits.</p>
        </div>
    </div>
    </div>
    </section>

<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/virtual-primary-care-1.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">1:1 Doctor <br class="d-none d-lg-block">Relationship.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 
                <p>Unlike Telemedicine, you ‘see’ the same doctor every time. </p>
                <p>When the doctor knows you, the most personalized and effective medical care can be delivered.</p>
                
               
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-1-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">Unlimited <br class="d-none d-lg-block">Virtual Visits.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                  
                <p>$0 Copays. $0 Deductibles. No out-of-pocket costs. No insurance needed! </p>
                <p>Schedule video visits between 9am-6pm, Monday-Friday!</p>
                
               
           </div>
       </div>
    </div>
</section>
<section >
<div class="container">
    <div class="row justify-content-center">
    <div class="col-6 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-2-min.png" class="img-fluid" />
        </div>
        <div class="col-6 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-2-1-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">24x7 Text <br class="d-none d-lg-block"> Messaging.   </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 <p>Efficient communication between you and your dedicated doctor. </p>
                <p> Just answer questions. Attach pictures and videos!</p> 
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-4-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">1,500 <br class="d-none d-lg-block">Conditions <br class="d-none d-lg-block">Covered.     </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 <p>Chronic conditions, men’s health, women’s health, children’s issues, mental health, preventive, and more are supported.</p>
                
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-3-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">Free Visits <br class="d-none d-lg-block">for Children.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                
                 <p>Free and unlimited visits for children &lt; 18 years old.</p>
                <p> You can invite a family member to join with a simple click!</p>
                
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-5-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">Electronic <br class="d-none d-lg-block">Prescriptions.    </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 
                 <p>Your dedicated doctor sends the prescription order to a pharmacy near you.</p>
                <p>You may request your prescription order to be sent to delivery services like Amazon Pharmacy as well.</p>
                
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-6-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="d-flex g-0 flex-column justify-content-center align-items-center">
    <div class="col-lg-4 col-md-8">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">Test Orders &amp; <br class="d-none d-lg-block">Follow Up.     </h4>
           </div>
           
            <div class="col-lg-5 col-md-8 fs--24 text-muted">
                 
                 <p>Your dedicated doctor sends orders for labs &amp; imaging, and does follow-ups.</p>
                <p> Screen share allows your doctor to review reports easily!</p>
                
           </div>
       </div>
    </div>
</section>
<section >
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/virtual-primary-care/vpc-7-min.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-sblue fs--40 mb-4 fw-bold">Specialist <br class="d-none d-lg-block">Referrals.     </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--24 text-muted">
                 
                 <p>Your dedicated doctor refers you to an in-network specialist when necessary.</p>
                <p> Your medical records are shared with each referral.</p>
                
           </div>
       </div>
    </div>
</section>