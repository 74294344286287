import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-quality-ratings',
  templateUrl: './doctor-quality-ratings.component.html',
  styleUrls: ['./doctor-quality-ratings.component.scss']
})
export class DoctorQualityRatingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
