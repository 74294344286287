/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
import * as i5 from "../header.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "footer", [["class", "bg-ali pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "container text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "row justify-content-center pb-md-5 mb-md-5 pb-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "col-md-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 14, "ul", [["class", "nav flex-column display-4 fw-bold lh-1 ff-raleway"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "nav-link text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Health Solutions"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "nav-link text-white"], ["href", "http://ai.alicorn.co"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Productivity Solutions"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "nav-link text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleabout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], null, null), (_l()(), i1.ɵted(-1, null, ["About Us"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "nav-link text-white"], ["href", "https://alicorn.co/products/login"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"])), (_l()(), i1.ɵeld(19, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "col-md-6 text-md-start text-center order-1 order-md-0 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A9 2021 ALICORN INC."])), (_l()(), i1.ɵeld(22, 0, null, null, 7, "div", [["class", "col-md-6 text-center text-md-end text-center order-0 order-md-1 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "a", [["class", "text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0 "])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "a", [["class", "text-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Privacy"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 7, 0, currVal_2); var currVal_7 = "/terms"; _ck(_v, 24, 0, currVal_7); var currVal_10 = "/privacy"; _ck(_v, 28, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).target; var currVal_1 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 14).target; var currVal_4 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 24).target; var currVal_6 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 28).target; var currVal_9 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_8, currVal_9); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [i5.HeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
