<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
        <h1 class="display-1 fw-bold text-ali-lpurple mb-md-3 mb-lg-5 ff-raleway">Labs Market</h1>
        <p class="fs--24 text-muted fw-light">Our doctors serve as physician shoppers to find <br class="d-none d-lg-block">lower cost facilities and save you money. </p>
        </div>
    </div>
    </div>
    </section>
<section class="section pt-0">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-8 text-center text-muted">
        <h4 class="ff-open fs--30">
        What does a basic blood test cost the employer? </h4>
        <p>
        The answer depends on where your employees are sent.</p>
        </div>
    </div>
      <div class="row justify-content-center my-md-5 my-3">
          <div class="col-md-12 chart-list">
              
              <div  *ngFor="let graph of divposition(); let inx=index" class="porgress-holder">
                  <div class="row">
                      <div class="col-lg-4 text-md-end"><span class="fs-18 fw-500 text-muted">{{graph.name}}</span></div>
                      <div class="col-lg-8 position-relative">
                           <div class="position-relative row align-items-center g-0 justify-content-center porgress-row" >
                               <div class="col-10 col-md-10 ">
                 <div class="progress bg-transparent position-relative overflow-visible " >
          
  <div class="progress-bar border d-flex flex-row justify-content-between bg-ali-red rounded-0 position-absolute h-100 fs--18 fw-500 overflow-visible" role="progressbar"   aria-valuemax="1000" [ngStyle]="{'left':graph.x1+'%','width':graph.x2+'%'}">
      
      <div class="d-flex justify-content-between fs--12 w-100 position-absolute text-ali-nnblue" style="top:-100%;" *ngIf="inx==0">
      <span>10th Percentile</span>
      <span> 
90th Percentile</span>
      </div>
      
      <div class="position-absolute top-0    text-ali-red" style="left: 0%;transform: translate(-120%,-20%);">${{graph.min}}</div>
      <div class="position-absolute top-0   text-ali-red" style="right:  0%;transform: translate(120%,-20%);">${{graph.max}}</div>
                     </div>
                     </div>
                        
                                   
                                      <div class="position-absolute top-50 border-top start-0 end-0 translate-middle-y mx-4" style="border-top-style: dashed !important; border-top-width: 0.1rem !important;"></div>
                     </div>
                            
                      </div>
                          
                          
                          
                      </div>
                      </div>
              </div>
          
          </div>
    </div>
    
    <div class="row justify-content-center">
    <div class="col-md-12 text-center   text-ali-red">
        <h4 class="ff-open  ">
     Insurance Negotiated Payments for a Comprehensive Metabolic Panel in 2016  </h4>
        <p>
        Source: The New York Times &amp; The Healthcare Cost Institute</p>
        </div>
    </div>
    </div>
</section>

<section class="section pt-0">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-lpurple fs--40 mb-4 fw-bold">Insurance <br class="d-none d-lg-block">Negotiated Lab Prices Vary <br class="d-none d-lg-block">Significantly.  </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted"> 
                 
                <p>Insurance negotiated prices don’t mean they are all the same. There is a wide range of negotiated prices for labs.</p>
                <p>Most employees simply go to a lab recommended by their doctor as their copay doesn’t change. However, the claims cost to the employer can be very high if lower cost facilities are not chosen by the doctor.</p>
           </div>
       </div>
    </div>
</section>

<section class="section pt-0">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-10">
        <div class="card card-body p-md-5 shadow-sm">
         <h4 class="text-ali-lpurple fs--40 mb-4 fw-bold">Labs Market  </h4>
            <p class="fs--22 text-muted">Our doctors find lower cost facility and save you money!</p>
            <div class="py-5">
                <div class="row">
                    <div class="col-lg-10">
                    
            <div class="row g-md-0">
                <div class="col-md-6 mb-3 mb-lg-5 d-flex">
                
                <div class="form-control rounded-pill form-control-lg px-4 py-3 text-black-25 fs--18">Search by Service or CPT</div>
                    <div class="border-end mx-3 d-none d-md-block"></div>
                </div>
                <div class="col mb-3 mb-lg-5"> 
                    <div class="form-control rounded-pill form-control-lg px-4 py-3 text-black-25 fs--18 ">Search by Zip Code</div>
                </div>
                </div>
                        <div class="d-grid gap-2 col-6 col-md-4 mx-auto ms-md-auto me-md-0 ">
  <div class="btn btn-ali btn-lg btn-block px-4 py-3  rounded-pill text-white" >Submit</div>

</div>
                        
                    
                    </div>
            </div>
            </div>
        </div>
        
           </div>
       </div>
    </div>
</section>

<section class="section pt-0">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali-lpurple fs--40 mb-4 fw-bold">Our Doctors <br class="d-none d-md-block">Find Lower <br class="d-none d-md-block">Cost Facilities <br class="d-none d-md-block">to Save You Money. </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted"> 
                
                <p> Our doctors use the Labs Market to find lower cost facilities for you. </p>
                <p>This saves money both to the employer and the employee.</p>
           </div>
       </div>
    </div>
</section>