<footer class="bg-ali pt-5">
<div class="container text-white">
    <div class="row justify-content-center pb-md-5 mb-md-5 pb-3 mb-3">
    <div class="col-md-7">
        <ul class="nav flex-column display-4 fw-bold lh-1 ff-raleway">
  <li class="nav-item">
    <a class="nav-link text-white"  [routerLink]="'/'">Health Solutions</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white" href="http://ai.alicorn.co" target="_blank">Productivity Solutions</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white"  [routerLink]="" (click)="toggleabout()">About Us</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-white"  href="https://alicorn.co/products/login" target="_blank" >Login</a>
  </li>
</ul>
        </div>
    
    </div>
    <div class="row">
    <div class="col-md-6 text-md-start text-center order-1 order-md-0 mb-3">&copy; 2021 ALICORN INC.</div>
    <div class="col-md-6 text-center text-md-end text-center order-0 order-md-1 mb-3">
        <a [routerLink]="'/terms'"  class="text-white">Terms</a>&nbsp;&nbsp;
        <a [routerLink]="'/privacy'"  class="text-white">Privacy</a>
        </div>
    </div>
    
    </div>
</footer>