<section class="section-1">
<div class="container">
    <div class="row justify-content-center">
    <div class="col-lg-8 text-center">
        <h1 class="display-1 fw-bold text-ali mb-md-5 ff-raleway">Prescription Membership</h1>
        <p class="fs--24 text-muted fw-light">Get up to 90% discounts on prescription medications.<br class="d-none d-lg-block"> You save money by using our membership card <br class="d-none d-lg-block">at major pharmacy stores. </p>
        </div>
    </div>
    </div>
    </section>
<section>
<div class="container-fluid">
    <div class="row justify-content-center">
    <div class="col-md-12 text-center">
        <img src="./assets/img/prescription-membership/pharmacy-membership-guy.png" class="img-fluid" />
        </div>
    </div>
    </div>
</section>
<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali fs--40 mb-4 fw-bold">Up to 90% <br class="d-none d-lg-block">Discount with <br class="d-none d-lg-block">Our Membership. </h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted">
                    
                <p>If you are in a high deductible health plan or don’t have insurance, medications can be expensive.</p>
                <p>Our Prescription Membership program offers up to 90% discounts. Plus top 1,000 common prescriptions are under $10 - often lower than your insurance copay/deductible.</p>
                <p>There are no claims and that cuts insurance costs to the employer.</p>
                
               
           </div>
       </div>
    </div>
</section>

<section class="section pt-0">
   <div class="container">
       <div class="row mb-5 pb-5">
    <div class="col-4">
         <img src="./assets/img/prescription-membership/mask-group-2.png" class="img-fluid" />
           </div>
           <div class="col-4">
         <img src="./assets/img/prescription-membership/cvs-pharmacy-logo.png" class="img-fluid" />
           </div>
           <div class="col-4">
         <img src="./assets/img/prescription-membership/cvs-pharmacy-at-target-logo.png" class="img-fluid" />
           </div>
       </div>
       <div class="row mt-4">
    <div class="col-3">
         <img src="./assets/img/prescription-membership/hyvee-logo.png" class="img-fluid" />
           </div>
           <div class="col-3">
         <img src="./assets/img/prescription-membership/mask-group-3.png" class="img-fluid" />
           </div>
           <div class="col-3">
         <img src="./assets/img/prescription-membership/mask-group-4.png" class="img-fluid" />
           </div>
           <div class="col-3">
         <img src="./assets/img/prescription-membership/mask-group-5.png" class="img-fluid" />
           </div>
       </div>
    </div>
</section>

<section class="section">
   <div class="container">
       <div class="row justify-content-center">
    <div class="col-lg-4 col-md-5">
        <h4 class="text-ali fs--40 mb-4 fw-bold">Membership <br class="d-none d-lg-block">Card Accepted <br class="d-none d-lg-block">Widely.</h4>
           </div>
           
            <div class="col-lg-5 col-md-7 fs--22 text-muted">
                    
                <p>Thousands of pharmacies across the United States accept our membership card.</p>
                <p> Major pharmacy and grocery chain stores such as CVS, Safeway, Krogers are included.</p>
                
           </div>
       </div>
    </div>
</section>