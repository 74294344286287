import { Component, OnInit, HostListener, Inject  } from '@angular/core';
import {HeaderService} from '../header.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
isOpen: boolean = false;
    IsAboutclick:boolean=false;
    isShadow:boolean=false;
    CurrentNavbar:string='';
  constructor(@Inject(DOCUMENT) private document: Document, private hs:HeaderService, private router:Router,private route:ActivatedRoute) { }
@HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
       
    if (document.body.scrollTop > 60 ||     
    document.documentElement.scrollTop > 60) {
      this.isShadow=true;
    }else{
        this.isShadow=false;
    }
  }
  ngOnInit() {
      
      
      
      this.hs.onHam().subscribe(data=>{
          this.isOpen=true;
          this.IsAboutclick=data.isabout;
     
      });
       var currentnavbar=this.getHeaderClasses();
       this.CurrentNavbar=currentnavbar;
      
        this.router.events.forEach((event) => {
             if(event instanceof  NavigationEnd) {
                this.IsAboutclick=false; 
                 this.isOpen=false;
                 currentnavbar=this.getHeaderClasses();
                 this.CurrentNavbar=currentnavbar;
               window.scrollTo(500, 0);
             }
            
        });
      
  }
    
    getHeaderClasses(): string | null {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['navclass']) {
        return child.snapshot.data['navclass'];
      } else {
        return '';
      }
    }
    return null;
  }
    
    toggleham(){
        if(this.IsAboutclick){
           this.IsAboutclick=false;
           }else{
             this.isOpen = !this.isOpen;    
           }
       
    }
    toggleabout(){
        this.IsAboutclick = !this.IsAboutclick; 
    }

}
